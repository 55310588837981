import { template as template_3fedc48b34144123a119536e03b6a694 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3fedc48b34144123a119536e03b6a694(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
