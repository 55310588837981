import { template as template_746921e77f4b4524848252a792586dad } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_746921e77f4b4524848252a792586dad(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
