import { template as template_3b0189678c294532af9cea5f9620572b } from "@ember/template-compiler";
const WelcomeHeader = template_3b0189678c294532af9cea5f9620572b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
