import { template as template_9cafafd32a164456ba8776d9c8cce47a } from "@ember/template-compiler";
const FKText = template_9cafafd32a164456ba8776d9c8cce47a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
