import { template as template_fa861f54f9ea4283a416826e4a52768a } from "@ember/template-compiler";
const FKLabel = template_fa861f54f9ea4283a416826e4a52768a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
